<template>
	<div id="my-page">
		<div class="screen-title">ユーザーマスタ登録</div>
		<b-container fluid>
			<validation-observer class="w-100" v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onSubmit)">
					<b-row class="mx-0 d-flex row-gap-20">
						<card class="my-0 w-100">
							<div class="card-title">基本情報</div>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="お名前"
										name="お名前"
										v-model="model.registerName"
										:rules="{ required: true }"
										label="お名前"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="メールアドレス"
										name="メールアドレス"
										v-model="model.mailAddress"
										label="メールアドレス"
										:rules="{
											required: true,
											email: true,
											checkExist: [model.mailAddress, 'email_address'],
										}"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										:labelClasses="'custom-form-input-label'"
										label="権限"
										name="権限"
										:rules="{ required: true }"
										:inputRequired="true"
									>
										<b-form-radio-group v-model="authoritySelect">
											<b-form-radio
												class="custom-control-inline custom__mr-15"
												v-for="item in authorityRadioOptions"
												v-bind="authorityRadioOptions"
												:key="item.value"
												:value="item.value"
												>{{ item.text }}</b-form-radio
											>
										</b-form-radio-group>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 col-gap-30 pb-27"> </b-row>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="会社名/屋号"
										name="会社名/屋号"
										v-model="model.companyName"
										label="会社名/屋号"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<b-col class="px-0">
									<!-- An added Area -->
									<base-input
										alternative
										placeholder="郵便番号"
										name="郵便番号"
										v-model="model.postcodeAddressJapan"
										@blur="onBlurPostcode"
										label="郵便番号"
										type="text"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
										:noteError="errorNoteMsg"
									>
									</base-input>
									<!-- An added Area -->
								</b-col>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="住所"
										name="住所"
										v-model="model.address"
										label="住所"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="電話番号"
										name="電話番号"
										v-model="model.phoneNumber"
										label="電話番号"
										type="text"
										@keydown="onKeyDown"
										@blur="checkPhoneNumber"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="FAX番号"
										name="FAX番号"
										v-model="model.faxNumber"
										label="FAX番号"
										type="text"
										@keydown="onKeyDown"
										@blur="checkFaxNumber"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>

							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="ログインID"
										name="ログインID"
										:rules="{
											required: true,
											checkExist: [model.loginId, 'username'],
											validate: [
												[model.loginId],
												'ログインIDは英数字しか設定できません',
												checkUserName,
											],
										}"
										v-model="model.loginId"
										label="ログインID"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="ログインパスワード"
										name="ログインパスワード"
										v-model="model.loginPassword"
										label="ログインパスワード"
										:rules="{ validFormat: true, required: true }"
										type="password"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:note="pwdRegex"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="確認パスワード"
										name="確認パスワード"
										v-model="confirmLoginPassword"
										:rules="{
											confirmPassword: model.loginPassword,
											required: true,
										}"
										label="確認パスワード"
										type="password"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">口座情報</div>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div
									class="col-md d-flex align-items-center col-gap-10 col-xs-12 res_margin_bottom px-0"
								>
									<base-input
										alternative
										placeholder="銀行名"
										name="銀行名"
										v-model="model.bankName"
										label="銀行名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:datalist="autoBanks"
										@on-select-option="onSelectOption"
									>
									</base-input>
									<div class="bank-caption">銀行</div>
								</div>
								<div class="col-md col-xs-12 px-0 d-flex align-items-center col-gap-10">
									<base-input
										alternative
										placeholder="支店名"
										name="支店名"
										v-model="model.bankBranchName"
										label="支店名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:datalist="autoBranches"
										@click="getBranchName(model.bankBranchName, bankCode)"
										@on-branch-empty="onBankEmpty"
										@on-select-option="onSelectOption"
									>
									</base-input>
									<div class="bank-caption">支店</div>
								</div>
							</b-row>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										label="口座種別"
										name="口座種別"
										:disabled="true"
										:labelClasses="'custom-form-input-label'"
									>
										<b-form-radio-group v-model="model.bankAccountType">
											<b-form-radio
												class="custom-control-inline custom__mr-15"
												v-for="item in bankAccountTypeOptions"
												v-bind="bankAccountTypeOptions"
												:key="item.value"
												:value="item.value"
											>
												{{ item.value }}</b-form-radio
											>
										</b-form-radio-group>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="口座名義人"
										name="口座名義人"
										v-model="model.bankAccountHolder"
										label="口座名義人"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										@input="onBankAccountHolderChange"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="口座番号"
										name="口座番号"
										v-model="model.bankAccountNumber"
										label="口座番号"
										type="number"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										@keypress="return isNumeric($event);"
										@input="maxLengthCheck()"
									>
									</base-input>
								</div>
							</b-row>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">印影</div>
							<b-col class="px-0">
								<img
									v-show="sealSrc.length"
									class="seal-img"
									:src="sealSrc"
									:key="sealSrc"
								/>
								<file-input
									@change="onUploadFile"
									acceptFormat="image/jpeg, image/jpg, image/png"
								></file-input>
							</b-col>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">備考</div>
							<textarea
								class="form-control"
								type="text"
								v-model="model.remark"
								style="resize: none"
								rows="5"
							></textarea>
						</card>
					</b-row>
					<b-row class="mx-0 px-0 my-4">
						<b-col class="w-100 d-flex justify-content-center">
							<button native-type="submit" class="customButton customButton__submit">
								登録
							</button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			:redirect="redirectLink"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import TransferService from '@/services/transfer.service';
import userService from '@/services/user.service';
import zipAddressService from '@/services/zip_address.service';

import { mapState } from 'vuex';
import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import autoBankMixin from '@/mixin/autoBankMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';
import CommonConst from '@/util/const';

import { Select, Option } from 'element-ui';
import FileInput from '@/components/Inputs/FileInput';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';

const wanakana = require('wanakana');
export default {
	name: 'UserRegister',
	mixins: [clientPaginationMixin, autoBankMixin, autoAddressMixin, postcodeMixin],
	components: {
		FileInput,
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
	},
	data() {
		return {
			notiModalId: 'user-register-noti',
			notiContent: '',
			notiSingleBtn: false,
			redirectLink: '',
			model: {
				registerName: undefined,
				companyName: undefined,
				postcode: undefined,
				address: undefined,
				clientRemark: undefined,
				phoneNumber: undefined,
				faxNumber: undefined,
				mailAddress: undefined,
				loginId: undefined,
				loginPassword: undefined,
				confirmLoginPassword: undefined,
				bankName: undefined,
				bankBranchName: undefined,
				bankAccountType: undefined,
				bankAccountHolder: undefined,
				bankAccountNumber: undefined,
				imprint: undefined,
				projectRemark: undefined,
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			sealSrc: '',
			confirmLoginPassword: undefined,
			classificationSelect: '',
			authoritySelect: '',
			loginUserId: '',
			classificationRadioOptions: [
				{
					text: '法人',
					value: '2',
				},
				{
					text: '個人',
					value: '1',
				},
			],
			authorityRadioOptions: [
				{
					text: 'クリエイター',
					value: '1',
				},
				{
					text: '管理者',
					value: '2',
				},
			],
			bankAccountTypeOptions: [
				{
					value: '普通',
				},
				{
					value: '当座',
				},
			],
			alertMessage: '',
			alertType: '',
			showAlert: false,
			pwdRegex: CommonConst.PWD_REGEX,
		};
	},

	watch: {
		async 'model.postcode'(newPostcode, oldPostcode) {
			if (!newPostcode) return;

			if (newPostcode.length === CommonConst.POST_CODE_LENGTH) {
				const response = await zipAddressService.get(newPostcode);
				const address = response.data;
				this.model.address = address.fullAddress;
			} else if (newPostcode.length > CommonConst.POST_CODE_LENGTH) {
				this.model.postcode = oldPostcode;
			} else {
				this.model.address = '';
			}
		},
	},

	methods: {
		checkUserName(username) {
			return /^[a-zA-Z0-9]+$/.test(username);
		},
		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onBankAccountHolderChange() {
			this.model.bankAccountHolder = wanakana.toKatakana(this.model.bankAccountHolder, {
				IMEMode: true,
			});
		},
		isNumeric(evt) {
			var theEvent = evt || window.event;
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
			var regex = /[0-9]/;
			if (!regex.test(key)) {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		},
		maxLengthCheck() {
			if (this.model.bankAccountNumber > 7) {
				this.model.bankAccountNumber = this.model.bankAccountNumber.slice(0, 7);
			}
		},
		async onSubmit() {
			this.notiSingleBtn = false;
			this.notiContent = '登録しますか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			this.registerUser();
		},

		async registerUser() {
			this.model.postcodeAddressJapan = this.convertToHalfWidth(
				this.model.postcodeAddressJapan
			);
			let newUserInfo = {
				account_holder: this.model.bankAccountHolder,
				account_number: this.model.bankAccountNumber,
				account_type: this.model.bankAccountType,
				company_name: this.model.companyName,
				address: this.model.address,
				bank_name: this.model.bankName,
				branch_name: this.model.bankBranchName,
				email_address: this.model.mailAddress,
				fax_number: this.model.faxNumber,
				phone_number: this.model.phoneNumber,
				// postal_code: this.model.postcode,
				postal_code: this.model.postcodeAddressJapan,
				remark: this.model.remark,
				update_user: this.loginUserId,
				username: this.model.loginId,
				company_seal: this.model.companySeal,
				register_name: this.model.registerName,
				login_password: this.model.loginPassword,
				type: this.classificationSelect,
				authority: this.authoritySelect,
			};
			let contents = this.deleteNullFields(newUserInfo);
			let [response, error] = await await userService.CreateUser(contents);
			if (response) {
				this.redirectLink = 'usermaster';
				this.notiSingleBtn = true;
				this.notiContent = '登録しました。';
				this.$bvModal.show(`${this.notiModalId}`);
			} else {
				this._showAlert('ユーザー登録に失敗しました。', 'error');
			}
		},

		deleteNullFields(obj) {
			for (let key in obj) {
				if (obj[key] == undefined || obj[key] == '') {
					delete obj[key];
				}
			}
			return obj;
		},

		_showAlert(message, type) {
			this.alertType = type;
			this.alertMessage = message;
			this.showAlert = true;
			setTimeout(() => {
				this.showAlert = false;
			}, CommonConst.ALERT_TIMEOUT);
		},

		onUploadFile(files) {
			[...files].forEach((file) => {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					let [uploadResponse, errorPostImg] = await await TransferService.postImg({
						content: reader.result.toString(),
						file_name: file.name,
						user_id: this.userInfo.id,
					});
					if (uploadResponse) {
						let key = uploadResponse.key;
						this.getLinkPreview(file, key);
					} else {
						console.log(errorPostImg);
					}
				};
			});
		},
		async getLinkPreview(file, key) {
			let [getResponse, errorGet] = await await TransferService.get(
				key,
				CommonConst.EXPIRATION
			);
			if (getResponse) {
				let linkPreview = getResponse.link;
				this.model.companySeal = {
					link: linkPreview,
					file_name: file.name,
					key: key,
				};
				this.sealSrc = linkPreview;
			} else {
				console.log('errorGet', errorGet);
			}
		},

		onAlertVisibleChange(data) {
			this.showAlert = data;
		},
	},
};
</script>
<style></style>
